import Img from 'components/Img'

import './modal-discover.scss'

const ModalDiscover = () => {
  return (
    <>
      <h2 className="modal-content__discover-title">
        Não sabe seu número de benefício?
      </h2>

      <Img
        file="num-beneficio-banner"
        className="modal-content__discover-banner"
      />

      <p className="modal-content__discover-text">
        Existem 3 formas de você descobrir o número do seu benefício:
      </p>

      <ol className="modal-content__discover-list">
        <li className="modal-content__discover-list-item">
          <p className="modal-content__discover-text">
            Para consultar o número do seu benefício de forma rápida, entre em
            sua conta no portal{' '}
            <a
              href="http://meu.inss.gov.br/"
              target="_blank"
              rel="noreferrer"
              className="modal-content__discover-link"
            >
              Meu INSS
            </a>{' '}
            e vá até a sessão{' '}
            <strong className="modal-content__discover-text--bold">
              meus benefícios
            </strong>
            , na tela inicial.
          </p>
        </li>
        <li className="modal-content__discover-list-item">
          <p className="modal-content__discover-text">
            Você também pode consultar o número do seu benefício na{' '}
            <strong className="modal-content__discover-text--bold">
              carta de concessão
            </strong>{' '}
            do seu INSS.
          </p>
        </li>
        <li className="modal-content__discover-list-item">
          <p className="modal-content__discover-text">
            Pode ligar também na Previdência Social pelo{' '}
            <strong className="modal-content__discover-text--bold">
              número 135
            </strong>{' '}
            . No atendimento, deverá informar seu CPF e confirmar algumas
            informaçōes cadastrais para manter seus dados protegidos.
          </p>
        </li>
      </ol>
    </>
  )
}

export default ModalDiscover
