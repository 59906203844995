import IconSetaDireita from '@mobi/ds/static/icons/outlined/seta-direita.svg'

import './modal-options.scss'

const ModalOptions = ({ setStepModal }) => {
  const updateStepModal = (newStep) => setStepModal(newStep)

  return (
    <ul className="modal-content__options">
      <li
        className="modal-content__options-item"
        onClick={() => updateStepModal('discover')}
      >
        <IconSetaDireita className="modal-content__options-icon" />
        <strong className="modal-content__options-text">
          Não sabe seu número de benefício?
        </strong>
      </li>
      <li
        className="modal-content__options-item"
        onClick={() => updateStepModal('disclaimer')}
      >
        <IconSetaDireita className="modal-content__options-icon" />
        <strong className="modal-content__options-text">
          Sobre dados consultados
        </strong>
      </li>
    </ul>
  )
}

export default ModalOptions
