import { useController } from 'react-hook-form'
import MaskedInput from 'react-input-mask'

import { Input } from '@itau-loans-www/shopping/src/components'
import mergeDeep from '@mobi/utils/object/mergeDeep'
import {
  handleChange,
  handleBlur
} from 'itau-jn6-dep-www/packages/ds/components/Input/handlerMethods.js'

const DEFAULT_MESSAGES = {
  required: 'campo obrigatório',
  number: 'O valor deve conter 10 números'
}

const DEFAULT_RULES = {
  validate: {
    number: (number) => number.length === 10
  }
}

const NumberInput = ({
  name = 'number',
  type = 'numeric',
  inputmode = 'numeric',
  pattern = '[0-9]*',
  label = 'Número do benefício',
  required = true,
  autoComplete = 'on',
  validators: { rules: propRules = {}, messages: propMessages = {} } = {},
  onChange = () => null,
  onBlur = () => null,
  control,
  disabled,
  ...props
}) => {
  const rules = mergeDeep(DEFAULT_RULES, propRules, { required })
  const errorMessages = mergeDeep(DEFAULT_MESSAGES, propMessages)
  const {
    field,
    fieldState: { invalid, error, isTouched }
  } = useController({ name, control, rules })

  const isInvalid = isTouched && invalid

  return (
    <MaskedInput
      mask="9999999999"
      maskChar=""
      disabled={disabled}
      value={field.value}
      name={field.name}
      ref={field.ref}
      onChange={(event) => handleChange(event, field, onChange)}
      onBlur={(event) => handleBlur(event, field, onBlur)}
    >
      {(inputProps) => (
        <Input.Default
          {...inputProps}
          {...props}
          label={label}
          type={type}
          inputmode={inputmode}
          pattern={pattern}
          autoComplete={autoComplete}
          isValid={!isInvalid}
          errorMessage={errorMessages[error?.type]}
          aria-required={required}
          disabled={disabled}
        />
      )}
    </MaskedInput>
  )
}

export default NumberInput
