const Loader = () => {
  return (
    <div className="loader">
      <div className="loader__spinner-select">
        <svg
          width="40"
          height="42"
          viewBox="0 0 40 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5975 0.607756C22.9438 0.0956562 27.3401 0.98564 31.145 3.14789C34.95 5.31014 37.9646 8.63154 39.749 12.6276C40.1694 13.569 39.747 14.6728 38.8057 15.0932C37.8644 15.5135 36.7605 15.0912 36.3401 14.1499C34.8801 10.8804 32.4137 8.16285 29.3005 6.39373C26.1874 4.62462 22.5904 3.89645 19.0343 4.31544C15.4782 4.73443 12.149 6.27866 9.53229 8.72288C6.91558 11.1671 5.14826 14.3835 4.48812 17.9028C3.82798 21.4221 4.30956 25.0603 5.86261 28.2866C7.41565 31.513 9.95892 34.1588 13.1214 35.838C16.284 37.5173 19.9003 38.1421 23.4429 37.6215C24.4629 37.4716 25.4113 38.1769 25.5612 39.1969C25.7111 40.2169 25.0058 41.1652 23.9858 41.3151C19.6559 41.9515 15.2359 41.1878 11.3706 39.1354C7.50532 37.0829 4.39688 33.8492 2.49871 29.9059C0.600539 25.9626 0.0119471 21.5159 0.818785 17.2145C1.62562 12.9131 3.78568 8.98201 6.98388 5.99463C10.1821 3.00725 14.2511 1.11986 18.5975 0.607756Z"
            fill="#FF6200"
          />
        </svg>
      </div>
    </div>
  )
}

export default Loader
