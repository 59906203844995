import { useEffect } from 'react'

import analytics from '@mobi/libraries/analytics'

const useAgreement = () => {
  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'formulario-inss-aposentado' })
  }, [])
}

export default useAgreement
