import auth from '@itau-loans-www/shopping/src/services/http/auth'
import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getAgreementData = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'agreement',
    method: 'get'
  })

  return data
}

const getAgreementState = async (agreement_type_id) => {
  const { data } = await auth({}).then((_http) => {
    return _http.get(
      `v2/itau-360-shopping-api/agreement/type/${agreement_type_id}/state/`,
      {}
    )
  })

  return data
}
const putAgreementData = async (requestBody) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'agreement',
    method: 'put',
    body: requestBody
  })

  return data
}

export default { getAgreementData, getAgreementState, putAgreementData }
