import { useState, useEffect } from 'react'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo, storage } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from './services'

export const useAgreementForm = () => {
  const [isButtonLoading, setButtonLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(true)
  const [statesOptions, setStatesOptions] = useState([])

  const {
    leadData: { agreement, hasActiveContract },
    changeLeadData
  } = useLead()

  const [fieldLoading, setFieldLoading] = useState({
    state: false,
    government: false
  })

  const [agreementId, setAgreementId] = useState(
    agreement?.type?.agreementId ||
      agreement?.state?.agreementId ||
      agreement?.government?.value
  )

  const getStates = (agreementTypeId) => {
    services
      .getAgreementState(agreementTypeId)
      .then(({ states }) => {
        const statesList = states.map((state) => {
          return {
            label: state['state_name'],
            value: state['state_id'],
            agreementId: state['agreement_id'] || null,
            government: state['government_name'],
            agreementTypeId: agreementTypeId
          }
        })

        setStatesOptions(statesList)
      })
      .finally(() => {
        setFieldLoading({ ...fieldLoading, state: false })
      })
  }

  const onSubmit = (values) => {
    setButtonLoading(true)

    const gaBenefit = values.hasAgreement ? 'ComBeneficio' : 'SemBeneficio'

    storage.session.setItem('ga_benefit', gaBenefit)

    const requestBody = {
      agreement_id: agreementId,
      has_authorized_dataprev_data_access: values.hasAgreement || false,
      agreement_customer_number: values.number,
      has_agreement_number: values.number ? true : false
    }

    changeLeadData({
      agreement: {
        ...agreement,
        agreementNumber: values.number,
        hasAgreement: values.hasAgreement
      }
    })

    analytics.track('buttonClick', {
      currentPage: 'formulario-inss-aposentado',
      detail: 'click-step-inss'
    })

    services
      .putAgreementData(requestBody)
      .then(({ next_step }) => {
        goTo(next_step)
      })
      .catch((error) => {
        console.error('useAgreementForm onSubmit |', { error })

        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'useAgreementForm | onSubmit',
          payload
        })
      })
      .finally(() => {
        setButtonLoading(false)
      })
  }

  useEffect(() => {
    setGetLoading(true)
    getStates('1')

    changeLeadData({
      agreement: {
        ...agreement,
        hasAgreement: ''
      }
    })

    services
      .getAgreementData()
      .then(({ has_active_loan_contracts, previous_step }) => {
        changeLeadData({
          previousStep: previous_step,
          hasActiveContract: has_active_loan_contracts
        })
      })
      .finally(() => setGetLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    fieldLoading,
    statesOptions,
    getLoading,
    isButtonLoading,
    onSubmit,
    setAgreementId,
    agreement,
    changeLeadData,
    hasActiveContracts: hasActiveContract
  }
}
