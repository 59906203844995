import { useState } from 'react'

import { If, Checkbox, Modal } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import IconSetaDireita from '@mobi/ds/static/icons/outlined/seta-direita.svg'
import analytics from '@mobi/libraries/analytics'

import ModalContent from '../ModalContent/index'
import './exception-checkbox.scss'

const ExceptionCheckbox = ({
  control,
  changeLeadData,
  agreement,
  setValue
}) => {
  const [showModal, setShowModal] = useState(false)
  const [stepModal, setStepModal] = useState('initial')
  const { leadData } = useLead()

  const onCloseClickModal = () => {
    setShowModal(false)
    setStepModal('initial')
  }

  return (
    <div className="exception-checkbox__wrapper">
      <Checkbox
        name="hasAgreement"
        label="Autorizo o Itaú a consultar meus dados do INSS"
        control={control}
        onClick={(value) => {
          setValue('hasAgreement', value.target.checked)
          analytics.track('buttonClick', {
            currentPage: 'formulario-inss-aposentado',
            detail: 'click-inss-aceite'
          })

          changeLeadData({
            agreement: {
              ...agreement,
              hasAgreement: value.target.checked
            }
          })
        }}
      />

      <div className="exception-checkbox__disclaimer">
        <button
          type="button"
          onClick={() => {
            setShowModal(true)
          }}
          className="exception-checkbox__disclaimer-button"
        >
          <span className="exception-checkbox__disclaimer-text">
            Dúvidas sobre refinanciamento e dados consultados?
          </span>
          <div className="exception-checkbox__link-wrapper">
            <span className="exception-checkbox__link">
              Clique aqui e saiba mais
            </span>
            <IconSetaDireita className="exception-checkbox__icon" />
          </div>
        </button>
      </div>

      <If
        condition={leadData?.hasActiveContract}
        renderIf={
          <Modal
            type="pop"
            show={showModal}
            onCloseClick={onCloseClickModal}
            primary={{
              action: onCloseClickModal,
              label: 'Ok, entendi'
            }}
          >
            <ModalContent stepModal="disclaimer" />
          </Modal>
        }
        renderElse={
          <Modal
            type="pop"
            show={showModal}
            onCloseClick={onCloseClickModal}
            primary={{
              action: onCloseClickModal,
              label: 'entendi'
            }}
            secondary={
              stepModal !== 'initial'
                ? {
                    action: () => setStepModal('initial'),
                    label: 'voltar'
                  }
                : undefined
            }
          >
            <ModalContent stepModal={stepModal} setStepModal={setStepModal} />
          </Modal>
        }
      />
    </div>
  )
}

export default ExceptionCheckbox
