import './modal-disclaimer.scss'

const ModalDisclaimer = () => {
  const listsData = {
    auth: ['CPF', 'Data de Nascimento', 'Nome Completo'],
    benefit: [
      'Número do Benefício',
      'Situação do Benefício',
      'Espécie do Benefício',
      'Indicação de que o Benefício foi Concedido por Liminar',
      'Data de Cessação do Benefício – DCB (se houver)',
      'Possui Representante Legal',
      'Possui Procurador',
      'Possui Entidade Representação',
      'Pensão Alimentícia',
      'Bloqueado para Empréstimo',
      'Data da última Perícia Médica',
      'Data do Despacho do Benefício – DDB'
    ],
    benefitPayment: [
      'UF onde o Beneficiário recebe os proventos',
      'Tipo de Crédito (Cartão ou Conta-Corrente)',
      'Indicação da Instituição Financeira que paga o benefício',
      'Agência Pagadora',
      'Conta-Corrente onde o benefício é pago',
      'Margem Consignável Disponível',
      'Margem Consignável Disponível para Cartão',
      'Valor Limite para Cartão',
      'Quantidade de empréstimos ativos/suspensos'
    ]
  }

  const List = ({ arr }) => {
    return (
      <ul className="modal-content__disclaimer-list">
        {arr.map((item, index) => (
          <li key={index} className="modal-content__disclaimer-list-item">
            <span className="modal-content__disclaimer-list-icon">•</span>{' '}
            {item}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <>
      <h2 className="modal-content__disclaimer-title">
        Sobre os dados consultados
      </h2>

      <p className="modal-content__disclaimer-text">
        Banco Credor: BANCO ITAÚ CONSIGNADO S.A., CNPJ/MF nº 33.885.724/0001-19,
        Pça. Alfredo Egydio de Souza Aranha, 100, T. Alfredo Egydio, 5º andar,
        São Paulo – SP
      </p>

      <h3 className="modal-content__disclaimer-subtitle">AUTORIZAÇÃO</h3>

      <p className="modal-content__disclaimer-text--bold">
        Autorização para que o INSS/DATAPREV disponibilize as informações abaixo
        indicadas para apoiar a contratação/simulação de empréstimo
        consignado/cartão consignado de benefícios do INSS para subsidiar a
        proposta pelo Banco Credor.
      </p>

      <h3 className="modal-content__disclaimer-subtitle">
        Dados de Identificação:
      </h3>

      <ul className="modal-content__disclaimer-list">
        <List arr={listsData.auth} />
      </ul>

      <h3 className="modal-content__disclaimer-subtitle">Dados do Benefício</h3>

      <ul className="modal-content__disclaimer-list">
        <List arr={listsData.benefit} />
      </ul>

      <h3 className="modal-content__disclaimer-subtitle">
        Dados do Pagamento do Benefício
      </h3>

      <ul className="modal-content__disclaimer-list">
        <List arr={listsData.benefitPayment} />
      </ul>

      <h3 className="modal-content__disclaimer-subtitle--regular">
        Este termo autoriza esta Instituição Financeira a consultar as
        informações acima descritas durante um período de{' '}
        <strong className="modal-content__disclaimer-subtitle">30 dias</strong>.
        Este pedido poderá ser efetuado pela Instituição Financeira em até{' '}
        <strong className="modal-content__disclaimer-subtitle">45 dias</strong>{' '}
        após a assinatura deste instrumento.
      </h3>
    </>
  )
}

export default ModalDisclaimer
