import { useForm } from 'react-hook-form'

import { If, Select, Navigation } from '@itau-loans-www/shopping/src/components'
import { cleanAccents } from 'utils'

import Shimmer from 'components/Shimmer'

import ExceptionCheckbox from '../ExceptionCheckbox/index'
import Loader from '../Loader'
import NumberInput from '../NumberInput/index'
import './agreement-form.scss'
import { useAgreementForm } from './hooks'

const InssForm = () => {
  const {
    fieldLoading,
    statesOptions,
    isButtonLoading,
    onSubmit,
    setAgreementId,
    agreement,
    changeLeadData,
    hasActiveContracts,
    getLoading
  } = useAgreementForm()

  const initialValues = {
    type: agreement?.type?.label || '',
    state: agreement?.state?.label || '',
    number: agreement?.agreementNumber || '',
    hasAgreement: ''
  }

  const {
    control,
    handleSubmit,
    formState: { isValid },
    ...formProps
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const isFormValid =
    agreement?.hasAgreement && isValid && statesOptions.length > 0

  return (
    <>
      <If
        condition={getLoading}
        renderIf={
          <>
            <Shimmer margin="10px 0 30px 0" height="48px" />
            <Shimmer margin="0 0 40px 0" height="48px" />
            <Shimmer margin="0 0 40px 0" widthDesktop="60%" height="48px" />
            <Shimmer margin="0 0 70px 0" height="114px" />
            <Shimmer margin="0 0 10px 0" widthDesktop="50%" height="50px" />
          </>
        }
        renderElse={
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <If
              condition={!hasActiveContracts}
              renderIf={
                <>
                  <div className="agreement-form__field">
                    <If
                      condition={
                        fieldLoading.state &&
                        !statesOptions &&
                        statesOptions.length <= 0
                      }
                      renderIf={<Loader />}
                      renderElse={
                        <Select
                          label="Estado por onde recebe seu beneficio"
                          name="state"
                          placeholder="buscar"
                          control={control}
                          options={statesOptions}
                          disabled={getLoading}
                          formProps={formProps}
                          filterOptions={({ options, search }) => {
                            return options.filter((option) =>
                              cleanAccents(option.label)
                                .toLowerCase()
                                .includes(cleanAccents(search).toLowerCase())
                            )
                          }}
                          onChange={(selectedOption) => {
                            if (typeof selectedOption !== 'string') {
                              const { agreementId } = selectedOption
                              changeLeadData({
                                agreement: {
                                  ...agreement,
                                  state: { ...selectedOption }
                                }
                              })

                              setAgreementId(agreementId)
                            }
                          }}
                        />
                      }
                    />
                  </div>
                  <div className="agreement-form__field">
                    <NumberInput control={control} />
                  </div>
                </>
              }
            />
            <div className="agreement-form__field">
              <ExceptionCheckbox
                control={control}
                changeLeadData={changeLeadData}
                agreement={agreement}
                setValue={formProps.setValue}
                hasActiveContracts={hasActiveContracts}
              />
            </div>

            <Navigation
              isNextButtonDisabled={!isFormValid}
              isNextStepButtonLoading={isButtonLoading}
              submit
              nextStep
              nextButtonId="agreement-data-next-btn"
            />
          </form>
        }
      />
    </>
  )
}

export default InssForm
